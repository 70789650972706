import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Button, Content, CTABox, Spacer } from 'components';
export const _frontmatter = {
  "title": "Markdown"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Content mdxType="Content">
	<Spacer height={100} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
	<h1>Markdown</h1>
	<p>
		If you don't know what Markdown is, prepare to fall in love. We all know
		the toolbars seen in any rich text editor. Click this to make bold;
		click that to start a list.
	</p>
	<p>
		Every time you take your hands off your keyboard, you lose time. The
		most precious thing you have.
	</p>
	<p>
		But imagine formatting the text <b>while</b> you're typing; wouldn't
		that be amazing? So much faster and easy. This is what Markdown is.
	</p>
	<p>
		And here comes the best part - it's dead easy, and you'll grasp the idea
		in no time. Just start typing and see the magic happen.
	</p>
	<p>
		But if you prefer the toolbar instead, we got you covered. Simply hit{' '}
		<code>/</code>, and you'll get a list you can select from to add your
		desired note element.
	</p>
	<h2>Markdown cheat-sheet</h2>
	<p>
		To give you an idea of how it works, here's a short cheat sheet on
		formatting the text while typing.
	</p>
	<code>
		# Top level heading
		<br />
		## Second level heading
		<br />
		### Third level heading
		<br />
		<br />
		1. Ordered list
		<br />
		2. Another item will be picked-up automatically
		<br />
		<br />
		- Unordered list
		<br />
		- Another item will be picked-up automatically
		<br />
		<br />
		[ ] To do item
		<br />
		[x] This one's done
		<br />
		<br />
		[This is a link](https://an.awesome.link)
		<br />
		<br />
		This is **all you need to know to write bold** or *italics*.
		<br />
		<br />
		It even supports `code`
		<br />
		<br />
		> And blockquotes
		<br />
	</code>
    </Content>
    <Spacer height={60} responsive={{
      md: 40,
      sm: 30
    }} mdxType="Spacer" />
    <CTABox color="banana99" description="Enough of theory, proceed to practice!" heading="Get your notes a safe home they deserve" mdxType="CTABox">
	<Button arrow="right" color="berry99" to="https://use.madnotes.app" type="plain" mdxType="Button">
		Start noting
	</Button>
	<Button color="berry99" to="/#pricing" type="ghost" gtag={['event', 'click', {
        event_label: 'Pricing'
      }]} mdxType="Button">
		See Pricing
	</Button>
    </CTABox>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      